/* tslint:disable */
/* eslint-disable */
/**
 * ddx-alunahealth
 * # Authentication Guide  We use the Bearer Token authentication mechanism for secure access to our APIs. This guide details the process of obtaining the token and using it to access our endpoints.  ## Obtaining the Bearer Token  1. **Endpoint**:      Retrieve the token from the following endpoint:     ```http    POST https://ddx.openworkflows.com/realms/ddx/protocol/openid-connect/token    ```  2. **Request Headers**:       ```http    Content-Type: application/x-www-form-urlencoded    ```  3. **Request Body** (x-www-form-urlencoded):     ```plaintext    client_id=ddx-app    username=YOUR_USERNAME    password=YOUR_PASSWORD    grant_type=password    ```     Replace `YOUR_USERNAME` and `YOUR_PASSWORD` with your actual account credentials.  4. **Response**:      A successful JSON response includes the `access_token`. Store it securely and use it to authenticate the API requests.      ```json    {      \"access_token\": \"YOUR_ACCESS_TOKEN\",      \"expires_in\": 300,      \"refresh_expires_in\": 1800,      \"token_type\": \"Bearer\"    }    ```     ## Using the Bearer Token for API Access  With the Bearer Token in hand:   **Set Authorization Header**:      Include the token in the `Authorization` header for your HTTP requests:     ```http    Authorization: Bearer YOUR_ACCESS_TOKEN    ```     Replace `YOUR_ACCESS_TOKEN` with the token you obtained from the previous step. 
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConfigurationParameters {
    apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>);
    username?: string;
    password?: string;
    accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string) | ((name?: string, scopes?: string[]) => Promise<string>);
    basePath?: string;
    baseOptions?: any;
    formDataCtor?: new () => any;
}

export class Configuration {
    /**
     * parameter for apiKey security
     * @param name security name
     * @memberof Configuration
     */
    apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>);
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    username?: string;
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    password?: string;
    /**
     * parameter for oauth2 security
     * @param name security name
     * @param scopes oauth2 scope
     * @memberof Configuration
     */
    accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string) | ((name?: string, scopes?: string[]) => Promise<string>);
    /**
     * override base path
     *
     * @type {string}
     * @memberof Configuration
     */
    basePath?: string;
    /**
     * base options for axios calls
     *
     * @type {any}
     * @memberof Configuration
     */
    baseOptions?: any;
    /**
     * The FormData constructor that will be used to create multipart form data
     * requests. You can inject this here so that execution environments that
     * do not support the FormData class can still run the generated client.
     *
     * @type {new () => FormData}
     */
    formDataCtor?: new () => any;

    constructor(param: ConfigurationParameters = {}) {
        this.apiKey = param.apiKey;
        this.username = param.username;
        this.password = param.password;
        this.accessToken = param.accessToken;
        this.basePath = param.basePath;
        this.baseOptions = param.baseOptions;
        this.formDataCtor = param.formDataCtor;
    }

    /**
     * Check if the given MIME is a JSON MIME.
     * JSON MIME examples:
     *   application/json
     *   application/json; charset=UTF8
     *   APPLICATION/JSON
     *   application/vnd.company+json
     * @param mime - MIME (Multipurpose Internet Mail Extensions)
     * @return True if the given MIME is JSON, false otherwise.
     */
    public isJsonMime(mime: string): boolean {
        const jsonMime: RegExp = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
        return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
    }
}
