import React, {useEffect, useRef, useState} from 'react';
import {Button, List, message, Tag} from 'antd';
import {useApi} from '../ApiProvider';
import {io} from 'socket.io-client';
import {v4 as uuidv4} from 'uuid';
import ReactMarkdown from 'react-markdown';
import {
    AudioTwoTone,
    DeleteOutlined,
    DoubleRightOutlined,
    FileDoneOutlined,
    PaperClipOutlined,
    SendOutlined
} from '@ant-design/icons';
import Search from "antd/es/input/Search";
import {ImportedFileDto} from "../../generated/api";
import RecordRTC from "recordrtc";

// 1) Define some random waiting messages
const WAITING_MESSAGES = [
    "Thinking...",
    "Generating answer...",
    "Analyzing...",
    "Working on it...",
    "Doing some magic...",
    "Cracking the puzzle..."
];

// 2) Helper to pick a random phrase
function getRandomWaitMessage(): string {
    const idx = Math.floor(Math.random() * WAITING_MESSAGES.length);
    return WAITING_MESSAGES[idx];
}

// 3) Wave-text CSS: a moving gradient overlay on the text

interface ChatProps {
    executionId: string | undefined;
    chatName: string | undefined;
    tabName: string | undefined;
}

interface BubbleAction {
    label: string,
    action: () => void,
    icon?: any | undefined,
    disabled: boolean,
}

export function InternalChat(props: ChatProps) {
    const [messages, setMessages] = useState<any[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const inputRef = useRef<any>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const socketRef = useRef<any>(null);
    const [isRecording, setIsRecording] = useState<boolean>(false)
    const currentBotMessageIdRef = useRef<string | null>(null);
    const [disableInput, setDisabledInput] = useState(false);
    const [selectedSummarization, setSelectedSummarization] = useState<string>('')
    const [modalTitle, setModalTitle] = useState<string>('')
    const [openSummarizationDocModal, setOpenSummarizationDocModal] = useState<boolean>(false)
    const [importedFiles, setImportedFiles] = useState<ImportedFileDto[] | undefined>([])
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [processedFiles, setProcessedFiles] = useState<Set<string>>(new Set());
    const [isStreaming, setIsStreaming] = useState(false);
    const [recorder, setRecorder] = useState<RecordRTC | null>(null);
    const [recordingStream, setRecordingStream] = useState<MediaStream | null>(null);

    const handleFinishConversation = async () => {
        if (isRecording) stopRecording();
        await api.continueAgentsChain({executionId: props.executionId!, tabName: props.tabName!});
        setMessages((prevMessages) => [
            ...prevMessages,
            {id: uuidv4(), user: 'bot', message: 'Thanks! Your data was submitted successfully!'},
        ]);
        setDisabledInput(true);
    };

    const [quickActions, setQuickActions] = useState<BubbleAction[]>([
        {
            label: 'Finish Conversation',
            action: handleFinishConversation,
            icon: <DoubleRightOutlined/>,
            disabled: disableInput,
        }
    ]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        async function fetchMessages() {
            if (props.executionId && props.tabName) {
                const previousChat = await api.getChatHistory(props.executionId, props.tabName);
                if (previousChat.data) {
                    const nativeFiles = previousChat.data.importedFile?.map(file => {
                        const blob = new Blob([file.rawData!], {type: file.type})
                        return new File([blob], file.fileName, {type: file.type})
                    });
                    if (nativeFiles) setSelectedFiles(nativeFiles);
                    const formattedMessages = previousChat.data.chatHistoryMessages
                        ?.filter(ms => ms.text)
                        .map((msg1) => {
                            if (msg1.type !== 'user') {
                                msg1.text = msg1.text
                                    .replace(/<<option:.*?>>/g, '')
                                    .replace(/<<url:.*?>>/g, '')  // Remove all <<option:...>> tags
                                    .replace(/,\s*,/g, ',')       // Replace multiple commas with a single comma
                                    // .replace(/[,.\s]+$/g, '')     // Remove trailing commas, dots, or spaces
                                    // .replace(/[,.\s]+(?=[,.\s])/g, '') // Remove consecutive punctuation
                                    .trim();
                            }
                            return msg1;
                        })
                        .map((msg) => ({
                            id: uuidv4(),
                            user: msg.type === 'user' ? 'user' : 'bot',
                            message: msg.text,
                        }));
                    setMessages(formattedMessages ?? []);
                    if (previousChat.data.finished) setDisabledInput(true);
                } else {
                    setLoading(true);
                    await api
                        .processUserMessageFromChat(props.executionId ?? '', '', props?.tabName, uuidv4())
                        .then(() => {
                        })
                        .catch(() => setLoading(false));
                }
            }
        }

        fetchMessages();
    }, [props.executionId, props.tabName]);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const cleanOptionLabel = (label: string) => {
        return label.trim().replace(/[.,]+$/, ''); // Removes trailing punctuation (commas, dots)
    };

    useEffect(() => {
        setDisabledInput(false);
        setMessages([]);
        setTimeout(() => {
        }, 100);

        socketRef.current = io({transports: ['websocket', 'polling']});

        socketRef.current.on('internalChatToken', (data: string) => {
            const llmToken: { token: string; executionId: string; tabName: string; messageId: string } = JSON.parse(data);

            if (llmToken.executionId === props.executionId && llmToken.tabName === props.tabName) {
                setMessages((prevMessages) => {
                    const withoutTypingIndicator = prevMessages.filter((msg) => msg.id !== 'typing-indicator');
                    const actionRegex = /<<(option|url):(.*?)>>/g;
                    let match;
                    const foundOptions: string[] = [];
                    const foundUrls: string[] = [];

                    while ((match = actionRegex.exec(llmToken.token)) !== null) {
                        const actionType = match[1];  // "option" or "url"
                        const content = cleanOptionLabel(match[2]);

                        if (actionType === 'option') {
                            foundOptions.push(content);
                        } else if (actionType === 'url') {
                            foundUrls.push(content);
                        }
                    }

                    // 2) If any "options" were detected, create quick actions for them
                    if (foundOptions.length > 0) {
                        setQuickActions((prevActions) => {
                            // Convert each option into a quick action
                            const newActions = foundOptions.map((option) => ({
                                label: option,
                                action: () => {
                                    setInputValue(option);
                                    handleSendMessage(option);
                                },
                                disabled: disableInput,
                            }));
                            // Merge with existing quick actions if needed
                            const uniqueActions = [
                                ...prevActions.filter(
                                    (action) =>
                                        !foundOptions.includes(action.label) &&
                                        action.label !== 'Finish Conversation'
                                ),
                                ...newActions,
                            ];
                            // Keep "Finish Conversation" at the end if desired
                            const finishAction = prevActions.find(a => a.label === 'Finish Conversation');
                            if (finishAction) {
                                uniqueActions.push(finishAction);
                            }
                            return uniqueActions;
                        });
                    }
                    if (foundUrls.length > 0) {
                        setQuickActions((prevActions) => {
                            const parsedUrlActions = foundUrls.map((token) => {
                                const [urlLabel, urlLink] = token.split('|', 2);
                                return {
                                    label: urlLabel?.trim() ?? token,
                                    link: urlLink?.trim() ?? '',
                                };
                            });
                            const newUrlActions = parsedUrlActions.map(({label, link}) => ({
                                label,
                                action: () => {
                                    if (link.startsWith('http://') || link.startsWith('https://') || link.startsWith('www.')) {
                                        window.open(link, '_blank');
                                    } else {
                                        window.location.href = link;
                                    }
                                },
                                disabled: disableInput,
                            }));

                            const combinedActions = [
                                ...prevActions,
                                ...newUrlActions,
                            ];

                            const uniqueActions = combinedActions.filter((action, idx) =>
                                combinedActions.findIndex((a) => a.label === action.label) === idx
                            );
                            return uniqueActions;
                        });
                    }

                    const cleanMessage = llmToken.token
                        .replace(/<<(option|url):.*?>>/g, '')  // remove both tokens
                        .replace(/,\s*,/g, ',')
                        .replace(/[,.\s]+$/g, '')
                        .replace(/[,.\s]+(?=[,.\s])/g, '')
                        .trim();

                    if (llmToken.messageId !== currentBotMessageIdRef.current) {
                        currentBotMessageIdRef.current = llmToken.messageId;
                        return [
                            ...withoutTypingIndicator,
                            {
                                id: currentBotMessageIdRef.current,
                                user: 'bot',
                                message: cleanMessage,
                            },
                        ];
                    } else {
                        return withoutTypingIndicator.map((msg) =>
                            msg.id === currentBotMessageIdRef.current
                                ? {...msg, message: cleanMessage}
                                : msg
                        );
                    }
                });
                scrollToBottom();
            }
        });

        socketRef.current.on('finishedStreaming', (data: string) => {
            const llmToken: { token: string; executionId: string; tabName: string; uuid: string } = JSON.parse(data);
            if (llmToken.executionId === props.executionId && llmToken.tabName === props.tabName) {
                setLoading(false);
            }
        });

        return () => {
            socketRef.current.disconnect();
        };
    }, [props.executionId, props.tabName]);

    function openModal(summary: string, modalTitle: string) {
        if (summary === '') return;
        setSelectedSummarization(summary);
        setModalTitle(modalTitle);
        setOpenSummarizationDocModal(true);
    }

    const handleFileDelete = (index: number) => {
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    };

    function getSummaryForFile(file: File): string {
        if (!importedFiles) return '';
        const arr = importedFiles?.filter((im) => {
            return im.fileName === file.name;
        });
        return arr?.[0].summarizationText ?? '';
    }

    const handleFileOpen = (file: File) => {
        const url = URL.createObjectURL(file);
        window.open(url, "_blank");
    };

    function getExtractedTextForFile(file: File): string {
        if (!importedFiles) return '';
        const arr = importedFiles?.filter((im) => {
            return im.fileName === file.name;
        });
        return arr?.[0].extractedText ?? '';
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSendMessage = async (text?: string) => {
        const newFilesToSend = selectedFiles.filter(file => !processedFiles.has(file.name));
        let textToSend = text ? text : inputValue;
        if (textToSend.trim() === '' && newFilesToSend.length === 0) return;
        setQuickActions(quickActions.filter(ac => ac.label === 'Finish Conversation'));
        setLoading(true);
        const userMessage = textToSend.trim();
        setInputValue('');

        // Insert a random waiting message
        setMessages((prevMessages) => [
            ...prevMessages,
            {id: uuidv4(), user: 'user', message: userMessage},
            {
                id: 'typing-indicator',
                user: 'bot',
                message: getRandomWaitMessage()  // pick random text
            },
        ]);

        try {
            const newMessageId = uuidv4();
            await api.processUserMessageFromChat(
                props.executionId ?? '',
                userMessage,
                props?.tabName,
                newMessageId,
                newFilesToSend
            ).then(() => {
                setProcessedFiles(prev => new Set([...prev, ...newFilesToSend.map(file => file.name)]));
            });
        } catch (error: any) {
            setLoading(false);
            message.error('Failed to send message : ' + error.message);
        }
    };

    const stopRecording = async () => {
        if (recorder) {
            setIsRecording(false);
            recorder.stopRecording(async function () {
                const finalBlob = recorder.getBlob();
                // Send the final chunk to the backend
                const res = await api.transcriptAudio(
                    "true",
                    props.executionId!,
                    new File([finalBlob], "final_chunk.webm", {type: "video/webm"}),
                    ""
                );
                setInputValue(res.data);
                recordingStream?.getTracks().forEach(track => track.stop());
                setRecorder(null);
            });
        }
    };

    const handleFileChange = (files: FileList | null) => {
        if (!files) return;
        const acceptedExtensions = [".pdf", ".mp3", ".xml", ".json", ".m4a", ".docx", ".txt", ".jpg", ".png"];
        const maxFileSize = 100 * 1024 * 1024; // 100 MB in bytes

        const newFiles = Array.from(files).filter((file) => {
            if (file.size > maxFileSize) {
                message.error(`File size is too large for: ${file.name}.`);
                return false;
            }
            if (!acceptedExtensions.includes(file.name.substring(file.name.lastIndexOf('.')).toLowerCase())) {
                message.error(`Unsupported file type for: ${file.name}.`);
                return false;
            }
            return true;
        });

        setSelectedFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            newFiles.forEach((file) => {
                let fileName = file.name;
                const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
                let baseName = fileName.substring(0, fileName.lastIndexOf('.'));
                if (fileName.lastIndexOf('.') === -1) baseName = fileName; // Handle no extension

                let counter = 1;
                while (updatedFiles.some((existingFile) => existingFile.name === fileName)) {
                    fileName = `${baseName} (${counter})${fileExtension}`;
                    counter++;
                }
                updatedFiles.push(new File([file], fileName, {type: file.type}));
            });
            return updatedFiles;
        });
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
            setRecordingStream(stream);
            let initialStart = true;

            const options = {
                type: 'audio',
                mimeType: 'audio/webm',
                numberOfAudioChannels: 1,
                recorderType: RecordRTC.StereoAudioRecorder,
                checkForInactiveTracks: true,
                timeSlice: 5000,
                ondataavailable: async (blob) => {
                    // Send chunks every 5 seconds
                    const initialStartFlag = initialStart ? "true" : "false";
                    if (initialStart) initialStart = false;
                    const res = await api.transcriptAudio(
                        initialStartFlag,
                        props.executionId!,
                        new File([blob], "chunk.webm", {type: "video/webm"}),
                        inputValue
                    );
                    setInputValue(res.data);
                },
            } as RecordRTC.Options;

            const recordRTC = new RecordRTC(stream, options);
            setRecorder(recordRTC);
            recordRTC.startRecording();
            inputRef.current?.focus();
        });
    };

    function isSummarizationForFile(file: File) {
        if (!importedFiles) return;
        if (!file) return;
        const arr = importedFiles?.filter((im) => {
            return im.fileName === file.name;
        });
        return arr && arr.length !== 0 && arr[0].summarizationText !== '';
    }

    if (!props.executionId) {
        return null;
    }

    const finishAction = quickActions.find(action => action.label === 'Finish Conversation');
    const otherActions = quickActions.filter(action => action.label !== 'Finish Conversation');

    return (
        <>
            <div
                style={{position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
                <div
                    ref={chatContainerRef}
                    style={{
                        flex: 1,
                        overflowY: 'auto',
                    }}
                >
                    <List
                        dataSource={messages}
                        renderItem={(item) => (
                            <List.Item
                                key={item.id}
                                style={{
                                    justifyContent: item.user === 'user' ? 'flex-end' : 'flex-start',
                                    border: 'none',
                                    width: '100%',
                                }}
                            >
                                {/* If this is the 'typing-indicator', we apply the wave effect class */}
                                {item.id === 'typing-indicator' && item.message ? (
                                    // No background container, just wave-text
                                    <div className="wave-text" style={{fontSize: '1rem', paddingLeft: 5}}>
                                        {item.message}
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            maxWidth: '88%',
                                            backgroundColor: item.user === 'user' ? '#1890ff' : '#f1f1f1',
                                            color: item.user === 'user' ? '#fff' : '#000',
                                            padding: '6px 14px',
                                            borderRadius: '6px',
                                        }}
                                    >
                                        <ReactMarkdown
                                            components={{
                                                a: ({href, children, ...props}) => {
                                                    const isSameDomain = href?.includes("autonomous.health");
                                                    return (
                                                        <a
                                                            href={href}
                                                            {...props}
                                                            target={isSameDomain ? "_self" : "_blank"}
                                                            rel={isSameDomain ? undefined : "noopener noreferrer"}
                                                        >
                                                            {children}
                                                        </a>
                                                    );
                                                },
                                                pre: ({node, ...props}) => (
                                                    <pre style={{whiteSpace: 'pre-line'}} {...props} />
                                                ),
                                            }}
                                        >
                                            {item.message || ''}
                                        </ReactMarkdown>
                                    </div>
                                )}
                            </List.Item>
                        )}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 16px',
                        backgroundColor: 'transparent',
                        justifyContent: 'space-between'
                    }}
                >
                    <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                        {otherActions.map((actionItem, idx) => (
                            <Tag
                                key={idx}
                                color="blue"
                                onClick={!actionItem.disabled ? actionItem.action : undefined}
                                style={{
                                    cursor: actionItem.disabled ? 'not-allowed' : 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '6px 14px',
                                    borderRadius: '6px'
                                }}
                            >
                                {actionItem.icon ? <span style={{marginRight: '4px'}}>{actionItem.icon}</span> : null}
                                {actionItem.label}
                            </Tag>
                        ))}
                    </div>
                    {(finishAction && !disableInput) && (
                        <Tag
                            color="volcano"
                            aria-disabled={finishAction.disabled}
                            onClick={!finishAction.disabled ? finishAction.action : undefined}
                            style={{
                                cursor: finishAction.disabled ? 'not-allowed' : 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '4px 14px',
                                borderRadius: '6px'
                            }}
                        >
                            {finishAction.icon ? <span style={{marginRight: '4px'}}>{finishAction.icon}</span> : null}
                            {finishAction.label}
                        </Tag>
                    )}
                </div>

                <div
                    style={{
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '8px',
                        backgroundColor: '#fff',
                        borderTop: '1px solid #f0f0f0',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative'
                    }}
                >
                    <Search
                        disabled={disableInput}
                        ref={inputRef}
                        placeholder="Type your message."
                        allowClear={false}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        enterButton={
                            <Button
                                loading={loading}
                                type="primary"
                                icon={<SendOutlined/>}
                                style={{
                                    height: '56px',
                                    width: '52px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            />
                        }
                        size="large"
                        suffix={
                            <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                <Button
                                    onClick={() => fileInputRef.current?.click()}
                                    style={{border: 'none'}}
                                    icon={<PaperClipOutlined/>}
                                    disabled={disableInput}
                                />
                                <Button
                                    className={isRecording ? 'recording' : ''}
                                    shape="circle"
                                    onClick={isRecording ? stopRecording : startRecording}
                                    icon={<AudioTwoTone/>}
                                    size="large"
                                    style={{border: 'none'}}
                                    disabled={disableInput}
                                />
                            </div>
                        }
                        onSearch={handleSendMessage}
                        style={{flexGrow: 1}}
                    />
                </div>
                <input
                    type="file"
                    onChange={(e) => {
                        handleFileChange(e.target.files);
                    }}
                    style={{display: 'none'}}
                    ref={fileInputRef}
                    accept=".pdf,.mp3,.xml,.json,.m4a,.docx,.txt,.jpg,.png"
                    multiple
                />

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        marginTop: '10px',
                    }}
                >
                    {selectedFiles.map((file, index) => (
                        <div
                            key={file.name}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                onClick={() => handleFileOpen(file)}
                                type="link"
                                style={{display: 'flex', alignItems: 'center'}}
                                title={file.name}
                            >
                                <PaperClipOutlined/>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        maxWidth: '150px',
                                        verticalAlign: 'middle',
                                    }}
                                >
                                    {file.name}
                                </span>
                            </Button>
                            {processedFiles.has(file.name) ? null : (
                                <DeleteOutlined
                                    className="delete-file-icon"
                                    size={1}
                                    onClick={() => handleFileDelete(index)}
                                    style={{color: '#fd4343'}}
                                />
                            )}
                            {isSummarizationForFile(file) ? (
                                <FileDoneOutlined
                                    title="Document summarization"
                                    style={{backgroundColor: 'yellow', marginLeft: '6px'}}
                                    onClick={() => {
                                        openModal(getSummaryForFile(file), 'File information');
                                    }}
                                />
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default InternalChat;
